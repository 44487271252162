// src/components/Blog.js

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MagnifyingGlassIcon, TagIcon } from '@heroicons/react/24/outline';

function BlogMain() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        async function fetchPosts() {
            try {
                const response = await fetch('https://form.ehoss.com/api/posts');
                const data = await response.json();
                setPosts(data);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        }

        fetchPosts();
    }, []);

    return (
        <div className="blog-container container">
            <div className=' pt-32 flex justify-center'>
                <div className='max-w-3xl w-full bg-gray relative rounded-xl flex flex-col p-16 justify-center items-center'>
                    <h1 className="text-3xl font-bold mb-4 text-white">Blog</h1>
                    <div className='flex bg-white gap-2 absolute rounded-xl p-4 bottom-[-25px] shadow-xl'>
                        <MagnifyingGlassIcon className='w-6 h-6 text-red' />
                        <input className='max-w-3xl w-full' type='text' placeholder='Searh...' />
                    </div>
                </div>
            </div>
            <div>
                <div className='flex justify-center items-center pt-16 gap-4'>
                    <a className='bg-white text-[13px] flex gap-2 text-red rounded-xl p-3 hover:bg-red hover:text-white' href='/blog/articles'>
                        <TagIcon className='w-4 h-4' />
                        All
                    </a>
                    <a className='bg-white text-[13px] flex gap-2 text-red rounded-xl p-3 hover:bg-red hover:text-white' href='/blog/articles'>
                        <TagIcon className='w-4 h-4' />
                        Articles
                    </a>
                    <a className='bg-white text-[13px] flex gap-2 text-red rounded-xl p-3 hover:bg-red hover:text-white' href='/blog/articles'>
                        <TagIcon className='w-4 h-4' />
                        Events
                    </a>
                </div>
            </div>

            <div className="post-list grid grid-cols-4">
                {posts.map(post => (
                    <a href={`blog/${post.link}`} key={post._id} className="post-item border-b py-4">
                        <img className='bg-gray rounded-xl mb-4' src={post.image} alt='' />
                        <p className='text-red'>{post.category}</p>
                        <h2 className="text-2xl font-semibold">
                            {post.title}
                        </h2>
                        <p className="text-gray-600 mt-2">{post.minidesckr}</p>
                    </a>
                ))}
            </div>
        </div>
    );
}

export default BlogMain;