import { useEffect, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Sidebar from '../sidebar/sidebar';
import logo from "../../assets/images/logo.svg";
import {  MapPinIcon, PencilIcon, PencilSquareIcon, PresentationChartBarIcon, XMarkIcon, CodeBracketIcon } from '@heroicons/react/24/outline';

function AdminProfile() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [jobs, setJobs] = useState([]);
    const [currentJob, setCurrentJob] = useState({ title: '', company: '', location: '', description: '', posted_date: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);


    
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);


    useEffect(() => {
        // Здесь можно реализовать проверку валидности токена, например, с проверкой localStorage или sessionStorage
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
            // Редирект на страницу логина или другая обработка неавторизованного доступа
            window.location = '/admin-login'
            console.log('Token is invalid or expired');
        }
    }, []);

    const logout = () => {
        // Удаление токена из localStorage или sessionStorage
        localStorage.removeItem('token');
        setIsLoggedIn(false);
        // Редирект на страницу логина или другая обработка выхода
        console.log('Logged out');
        window.location = '/admin-login'
    };


    useEffect(() => {
        fetchJobs();
    }, []);

    const fetchJobs = async () => {
        const response = await fetch('https://form.ehoss.com/api/vacancies');
        const data = await response.json();
        setJobs(data);
        console.log(data);
    };



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentJob((prevJob) => ({ ...prevJob, [name]: value }));
    };

    const handleDescriptionChange = (value) => {
        setCurrentJob((prevJob) => ({ ...prevJob, description: value }));
    };


    const handleSaveJob = async () => {
        const token = localStorage.getItem('token');
        console.log(currentJob);

        const response = await fetch(isEditing ? `https://form.ehoss.com/api/vacancies/${currentJob._id}` : 'https://form.ehoss.com/api/add-job', {

            method: isEditing ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(currentJob),
        });
        if (response.ok) {
            fetchJobs();
            setCurrentJob({ title: '', company: '', location: '', description: '', posted_date: '' });
            setIsEditing(false);
            window.location.reload()
        }
    };

    const handleEditJob = (job) => {
        console.log(job);
        setCurrentJob({
            title: job.title || '',
            company: job.company || '',
            location: job.location || '',
            description: job.description || '',
            posted_date: job.posted_date || '',
            _id: job._id // Убедитесь, что добавили это поле, если оно необходимо для идентификации вакансии
        });
        setIsEditing(true);
    };

    async function deleteJob(id) {
        try {
            const response = await fetch(`https://form.ehoss.com/api/vacancies/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    // Добавьте сюда ваш токен авторизации, если необходимо
                },
            });
            if (!response.ok) {
                throw new Error('Failed to delete job');
            }

            fetchJobs();
            setCurrentJob({ title: '', company: '', location: '', description: '', posted_date: '' });
            setIsEditing(false);
            window.location.reload()
            // Вернуть успешный результат или обработать его дальше
            return await response.json();
        } catch (error) {
            console.error('Error deleting job:', error);
            // Обработка ошибок, если нужно
        }
    }

    return (
        <div className="flex overflow-hidden h-screen bg-[#242424]">
            <Sidebar nav={1} />
            <main className="flex flex-col w-full h-[100vh] p-8">


                {isModalOpen && (
                    <div className='fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-20'>
                        <div className='flex flex-col gap-4 bg-[#1d1d1d] p-8 rounded-xl shadow-2xl relative'>
                            <button
                                onClick={handleCloseModal}
                                className='absolute top-4 right-4 text-white'
                            >
                                <XMarkIcon className='w-6 h-6' />
                            </button>
                            <div className="mb-6 max-w-4xl bg-[#1d1d1d] p-4 rounded-xl overflow-auto h-[600px]">
                                <h2 className="text-xl mb-4 text-white font-bold">{isEditing ? 'Edit Job' : 'Add New Job'}</h2>
                                <input
                                    type="text"
                                    name="title"
                                    placeholder="Job Title"
                                    value={currentJob.title}
                                    onChange={handleInputChange}
                                    className="border p-2 mb-2 w-full text-white bg-transparent border-white rounded-xl"
                                />
                                {/* <input
                        type="text"
                        name="company"
                        placeholder="Company"
                        value={currentJob.company}
                        onChange={handleInputChange}
                        className="border p-2 mb-2 w-full"
                    /> */}
                                <input
                                    type="text"
                                    name="location"
                                    placeholder="Location"
                                    value={currentJob.location}
                                    onChange={handleInputChange}
                                    className="border p-2 mb-2 w-full text-white bg-transparent border-white rounded-xl"
                                />
                                <ReactQuill value={currentJob.description} onChange={handleDescriptionChange} className="mb-2 bg-white rounded-xl border-none" />
                                <input
                                    type="date"
                                    name="posted_date"
                                    value={currentJob.posted_date}
                                    onChange={handleInputChange}
                                    className="border p-2 mb-2 rounded-xl w-full"
                                />
                                <button onClick={handleSaveJob} className="bg-blue-500 mr-2 text-white px-4 py-2 rounded">
                                    {isEditing ? 'Update Job' : 'Add Job'}
                                </button>

                                <button onClick={() => { deleteJob(currentJob._id) }} className={`${isEditing ? '' : 'hidden'} bg-red text-white px-4 py-2 rounded`}>
                                    DELETE
                                </button>


                            </div>
                        </div>
                    </div>
                )}




                <div className='mb-8 flex justify-between items-center'>
                    <div>
                        <h2 className='text-white font-bold text-[32px]'>
                            Вакансии
                        </h2>
                        <p className='text-white mt-2 opacity-30'> Всего открыто: {jobs.length}</p>


                    </div>
                    <div className='flex items-center gap-4'>
                        <p className='text-white'>
                            Add new
                        </p>
                        <div
                            onClick={() => setIsModalOpen(true)} // Открыть модальное окно при клике
                            className='bg-white p-2 rounded-full cursor-pointer'
                        >
                            <CodeBracketIcon className='bg-white text-red w-6' />
                        </div>
                    </div>


                </div>
                <main className="flex">
                 
                    <div className='w-1/2 overflow-auto h-[80vh]'>
                        <h2 className="text-xl mb-2 text-white font-bold">Открытые вакансии</h2>
                        <ul>
                            {jobs.map((job) => (
                                <li id={'job.id'} key={job.id} className="mb-2 text-white rounded-xl p-4 bg-[#1d1d1d]">
                                    <div className='flex justify-between items-center'>
                                        <h3 className="text-xl font-bold">{job.title}</h3>
                                        <p className='flex opacity-30 text-[12px]'>
                                            {job.posted_date}
                                        </p>
                                    </div>

                                    <p className='flex'>
                                        <MapPinIcon className='w-4 text-red' />
                                        {/* {job.company} */}
                                        {job.location}
                                    </p>
                                    <div dangerouslySetInnerHTML={{ __html: job.description }} />
                                    <button onClick={() => {
                                        handleEditJob(job)
                                        setIsModalOpen(true)
                                    }} className="bg-yellow-500 gap-2 items-center mt-6 flex text-white px-4 py-2 rounded">
                                        <PencilIcon className='w-4' />
                                        Edit
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </main>

            </main>
        </div>
    );
}

export default AdminProfile;