
import { useLocation } from "react-router-dom";
import { useRef, useState } from 'react';

function Order() {
    const location = useLocation();
    let close = useRef(null)
    const [error, setError] = useState(false);

    function CloseOrderModal() {
        close.current.classList.add('hidden')
    }

    let firstname = useRef(null);
    let lastname = useRef(null);
    let country = useRef(null);
    let adress = useRef(null);
    let email = useRef(null);

    const [isChecked, setIsChecked] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const usernameValue = firstname.current.value;
        const lastnamelValue = lastname.current.value;
        const emailValue = email.current.value;
        const countryValue = country.current.value;
        const adressValue = adress.current.value;


        if (!usernameValue || !lastnamelValue || !countryValue || !adressValue || !emailValue) {
            console.error('Error: One or more fields are empty');
            setError(true);
            return;
        } else {
            try {
                const response = await fetch('https://form.ehoss.com/order', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    mode: 'cors',
                    body: JSON.stringify({
                        url: `https://ehoss.com${location.pathname}`,
                        firstName: firstname.current.value,
                        lastName: lastname.current.value,
                        eMail: email.current.value,
                        country: country.current.value,
                        adress: adress.current.value,
                    }),
                });
                if (response.ok) {
                    setIsSent(true);
                    firstname.current.value = "";
                    lastname.current.value = "";
                    email.current.value = "";
                    country.current.value = "";
                    adress.current.value = "";
                    setError(false);
                } else {
                    // Обработка ошибок при отправке
                    console.error('Failed to send data');
                }
            } catch (error) {
                console.error('Failed to send data:', error);
            }
        }
    };

    const europeanCountries = [
        "Andorra",
        "Austria",
        "Belgium",
        "Bulgaria",
        "Croatia",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Estonia",
        "Finland",
        "France",
        "Germany",
        "Greece",
        "Hungary",
        "Iceland",
        "Italy",
        "Latvia",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Malta",
        "Monaco",
        "Netherlands",
        "Norway",
        "Poland",
        "Portugal",
        "Romania",
        "San Marino",
        "Slovakia",
        "Slovenia",
        "Spain",
        "Sweden",
        "Switzerland",
        "Turkey",
      ];

    return (

        <div ref={close} id="order-modal" tabIndex="-1" aria-hidden="true" className="hidden bg-[#000000] z-[9999] bg-opacity-40 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 justify-center items-center w-full md:inset-0 h-[100vh] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-gray rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Buy
                        </h3>
                        <button onClick={CloseOrderModal} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="white" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 md:p-5">
                        <form className="space-y-4" action="#">
                            <div>
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name <span className='text-red text-[20px]'>*</span></label>
                                <input ref={firstname} type="name" name="name" id="name" className="bg-gray border border-gray-300 text-white  text-sm rounded-lg focus:red focus:border-red block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                            </div>
                            <div>
                                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name <span className='text-red text-[20px]'>*</span></label>
                                <input ref={lastname} type="name" name="name" id="name" className="bg-gray border border-gray-300 text-white  text-sm rounded-lg focus:red focus:border-red block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                            </div>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email <span className='text-red text-[20px]'>*</span></label>
                                <input ref={email} type="email" name="email" id="email" className="bg-gray border border-gray-300 text-white  text-sm rounded-lg focus:red focus:border-red block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white" required />
                            </div>
                            <div>
                                <label htmlFor="country" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Country <span className='text-red text-[20px]'>*</span></label>
                                <select
                                    ref={country} type="text"
                                    name="country" id="message" placeholder="Your message"
                                    className="bg-gray border  border-gray-300 text-white text-sm rounded-lg focus:red focus:border-red block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500  dark:placeholder-gray-400 dark:text-white" required
                                >
                                    {europeanCountries.map((country, index) => (
                                        <option key={index} value={country}>{country}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="adress" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Adress of Delivery <span className='text-red text-[20px]'>*</span></label>
                                <textarea ref={adress} type="text" name="adress" id="message" placeholder="Your message" className="bg-gray border h-24 border-gray-300 text-white text-sm rounded-lg focus:red focus:border-red block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500  dark:placeholder-gray-400 dark:text-white" required />
                            </div>
                            <button
                                onClick={handleSubmit}
                                type="submit"
                                className={`w-full text-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${!isChecked ? 'opacity-30' : ''} ${isSent ? 'bg-[#1B391A] pointer-events-none text-white' : 'bg-white'}`}
                                disabled={!isChecked}
                            >
                                {isSent ? 'Sent!' : 'Send'}
                            </button>
                            <p className={`text-red text-[14px] ${error ? 'block' : 'hidden'}`}>Please fill in the required fields</p>
                            <div className="flex justify-between">
                                <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input
                                            id="remember"
                                            type="checkbox"
                                            value=""
                                            className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-600 dark:border-gray-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                            required
                                            onChange={handleCheckboxChange} // Обработчик изменения галочки
                                        />
                                    </div>
                                    <label htmlFor="remember" className="ms-2 text-[12px] font-medium text-white">
                                        I agree to the terms of the <a className="font-bold underline" href="/privacy-policy">Privacy Policy</a> and allow the processing of my personal data on legal grounds.
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Order;