import ReactFullpage from '@fullpage/react-fullpage';
import LineDot from '../../../components/line-dot/LineDot';
import Footer from '../../../container/footer/Footer';
import './horseowners.css'
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

import Line from '../../../components/line/Line';
import card1 from './../../audience/images/bg2.png'
import card2 from './../../audience/images/bg3.png'
import card3 from './../../audience/images/bg4.png'
import card4 from './../../audience/images/bg5.png'
import card5 from './../../audience/images/bg6.png'
import card7 from './../../audience/images/bg1.png'


function HorseOwners() {
  const location = useLocation();
  
  return (
    <>
      <ReactFullpage
        licenseKey={"LI54H-KIRX7-8PK2J-FJ697-REZYN"}
        scrollingSpeed={1000}
        navigation
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <Helmet>
                <title>EHOSS | Horse Owners</title>
                <meta name="robots" content="index, follow" />
                <meta name="keywords" content="Equine welfare, Horse Welfare, Equine Health Plan, feeding analysis software, equine management nutrition health & exercise" />
                <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
              </Helmet>
              <section className='section audience2'>
                <LineDot />
                <div className='container'>
                  <div className='pt-24 flex md:flex-row flex-col gap-8 h-[100vh]'>
                    <div className='md:w-1/2  text-white'>
                      <Breadcrumbs
                        items={[
                          { label: 'Who Needs?', url: '/' },
                          { label: 'For Animal Owners', url: '/audience' },
                          { label: 'Horse Owners', url: '/horse-owners' },
                        ]}
                      />
                      <div className='flex flex-col mt-12 items-start gap-4'>
                        <h2 className='text-[36px] font-medium'>
                          FOR THE HORSE OWNERS
                        </h2>
                        <p className='text-[14px]'>
                          Horse owners face various challenges, such as providing appropriate living conditions, offering a high level care, ensuring quality food, and promptly and professionally addressing any health issues that arise. This list of challenges can be continuously expanded and augmented.
                        </p>
                        <p className='text-[14px] font-bold'>
                          EHOSS platforms were created to improve horse’s life and simplify the life of its owners.
                        </p>

                        <p className='mt-6'>Our platforms help you to:</p>
                        <ul className='list flex flex-col gap-4 *:text-[14px] mb-6'>
                          <li>
                            Monitor and analyse your horse meals composition, size and frequency, as well as feed quality (hay or concentrated feed)
                          </li>
                          <li>
                            Monitor your horse daily routine, sleeping patterns and time spent outdoors (through a <a className='underline font-medium cursor-pointer' href='/monitoring-platform'>monitoring platform</a>).
                          </li>
                          <li>
                            Monitor temperature, humidity, and air quality inside the stall (via <a className='underline font-medium cursor-pointer' href='/monitoring-platform'>monitoring platform</a>)
                          </li>
                          <li>
                            Make the stall cleaner. <a className='underline font-medium cursor-pointer' href='/monitoring-platform'>Feed platform </a> minimises polluting from feed and hay dust; <a className='underline font-medium cursor-pointer' href='/monitoring-platform'>Enviroment Platform</a> kills the harmful microorganisms with a germicidal lamp.
                          </li>
                          <li>
                            Receive timely information about the weather conditions in the area where the horse lives through the <a className='underline font-medium cursor-pointer' href='/monitoring-platform'>monitoring platform</a>. This will allow:
                          </li>
                          <li>
                            Analyse what the horse does in the stall to be aware of its physical and mental condition (through the <a className='underline font-medium cursor-pointer' href='/monitoring-platform'>monitoring platform</a>)
                          </li>
                          <li>
                            Constantly monitor the stall (you’ll be able to see if there were any strangers and how the grooms treated the horse (through the <a className='underline font-medium cursor-pointer' href='/monitoring-platform'>monitoring platform</a>)
                          </li>
                        </ul>
                        <p className='font-bold'>
                          You can control all of this using your personal account or an application.
                        </p>

                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className='section overflow-hidden'>
                <Line />
                <div className='container flex gap-6 flex-col md:flex-row md:items-center md:justify-between'>
                  <div className=' w-full md:h-[100vh] pt-24 text-white'>
                    <div>
                      <h2 className='text-[36px] font-medium uppercase'>
                        more about who needs
                      </h2>
                    </div>
                    <div className='w-full mt-12'>
                      <div className='grid gap-2 grid-cols-2 md:grid-cols-6 w-full'>
                        {/* =========== card 1 ============= */}
                        <a href='/farmers' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>Farmers</h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card7} />
                        </a>
                        {/* =========== card 2 ============= */}
                        <a href='/horse-owners' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Horse Owners
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card1} />
                        </a>
                        {/* =========== card 3 ============= */}
                        <a href='/equestrian-facilities' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Equestrian Facilities
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card2} />
                        </a>
                        {/* =========== card 4 ============= */}
                        <a href='/equine-producers' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] text-center mt-4 font-semibold'>
                              Equine Product Producers
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card3} />
                        </a>
                        {/* =========== card 5 ============= */}
                        <a href='/veterinary' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] mt-4 font-semibold'>
                              Veterinary
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card4} />
                        </a>
                        {/* =========== card 6 ============= */}
                        <a href='/equestrians' className='rounded-xl cursor-pointer audience-link overflow-hidden text-[26px] relative'>
                          <div className='flex z-10 flex-col absolute inset-0 items-center justify-center'>
                            <div className='bg-white rounded-full flex items-center justify-center p-4 w-10 h-10'>
                              <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.509766 14.6586L6.50426 8.66406L0.509765 2.66957L2.36427 0.828124L10.2002 8.66406L2.36427 16.5L0.509766 14.6586Z" fill="#F20000" />
                              </svg>
                            </div>
                            <h3 className='text-white text-[16px] mt-4 font-semibold'>
                              Equestrians
                            </h3>
                          </div>
                          <img className='opacity-30 w-full object-cover h-[200px] z-[-1]' src={card5} />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </ReactFullpage.Wrapper>
          );
        }}
      />
    </>

  );
}

export default HorseOwners;