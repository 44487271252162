import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function PostPageMain() {
    // Используем параметр 'link' из URL вместо 'id'
    const { link } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        async function fetchPost() {
            try {
                // Запрос к API для получения поста по 'link'
                const response = await fetch(`https://form.ehoss.com/api/posts-link/${link}`);
                const data = await response.json();
                setPost(data);
            } catch (error) {
                console.error('Error fetching post:', error);
            }
        }

        fetchPost();
    }, [link]); // Обновляем зависимость на 'link'

    if (!post) return <p>Loading...</p>;

    return (
        <div className="post-page">
            <h1 className="text-4xl font-bold mb-4">{post.title}</h1>
            <p className="text-gray-600 mb-4">{post.date}</p>
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
            {post.image && (
                <img src={post.image} alt={post.title} className="my-4" />
            )}
            <div className="slider">
            {post.slider.length > 0 && (
    <div className="slider">
        {post.slider.map((slide, index) => (
            <img
                key={index}
                src={slide.path}
                alt={slide.alt}
                title={slide.title}
                className="slider-image my-2"
            />
        ))}
    </div>
)}
            </div>
        </div>
    );
}

export default PostPageMain;