import { useEffect, useState, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';
import Sidebar from '../sidebar/sidebar';
import { XMarkIcon, ListBulletIcon, ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import ReactQuill from 'react-quill';

function AdminProfilePostsList() {
    const [content, setContent] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allPosts, setAllPosts] = useState([]);
    const [currentPost, setCurrentPost] = useState(null); // Состояние для текущего редактируемого поста

    const quillRef = useRef(null);

    useEffect(() => {
        async function fetchFiles() {
            try {
                const response = await fetch('https://form.ehoss.com/api/posts');
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                const data = await response.json();
                setAllPosts(data);
            } catch (error) {
                console.error('Ошибка при загрузке файлов:', error);
            }
        }

        fetchFiles();
    }, []);

    const handleOpenModal = (post) => {
        setCurrentPost(post);
        console.log(post);
        
        setIsModalOpen(true);
        setContent(post.content); // Установить контент текущего поста в редакторе
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setCurrentPost(null);
        setContent('');
    };

    const handleDeletePost = async (postId) => {
        try {
            const response = await fetch(`https://form.ehoss.com/api/posts/${postId}`, {
                method: 'DELETE',
            });

            if (!response.ok) {
                throw new Error('Ошибка при удалении поста');
            }

            setAllPosts(allPosts.filter(post => post.id !== postId));
            console.log('Пост успешно удален');
        } catch (error) {
            console.error('Ошибка при удалении поста:', error);
        }
    };

    const handleEditPost = async () => {
        try {
            const updatedPost = { ...currentPost, content }; // Создание обновленного поста
            const response = await fetch(`https://form.ehoss.com/api/posts/${currentPost._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedPost),
            });

            if (!response.ok) {
                throw new Error('Ошибка при обновлении поста');
            }

            setAllPosts(allPosts.map(post => (post.id === currentPost.id ? updatedPost : post)));
            console.log('Пост успешно обновлен');
            handleCloseModal(); // Закрытие модального окна после успешного обновления
        } catch (error) {
            console.error('Ошибка при обновлении поста:', error);
        }
    };



















        const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [imageList, setImageList] = useState([]);

    useEffect(() => {
        async function fetchImages() {
            try {
                const response = await fetch('https://form.ehoss.com/api/files');
                const data = await response.json();
                const imageUrls = data.map(file => `https://form.ehoss.com/uploads/${file.file_name}`);
                setImageList(imageUrls);
            } catch (error) {
                console.error('Error fetching image list:', error);
            }
        }

        fetchImages();
    }, []);

    useEffect(() => {
        async function fetchFiles() {
            try {
                const response = await fetch('https://form.ehoss.com/api/posts');
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                const data = await response.json();
                setAllPosts(data);
            } catch (error) {
                console.error('Ошибка при загрузке файлов:', error);
            }
        }

        fetchFiles();
    }, []);

    useEffect(() => {
        async function fetchImages() {
            try {
                const response = await fetch('https://form.ehoss.com/api/files');
                const data = await response.json();
                const imageUrls = data.map(file => `https://form.ehoss.com/uploads/${file.file_name}`);
                setImageList(imageUrls);
            } catch (error) {
                console.error('Error fetching image list:', error);
            }
        }

        fetchImages();
    }, []);

    useEffect(() => {
        function initializeQuill() {
            if (quillRef.current) {
                const quill = quillRef.current.getEditor();
                const toolbar = quill.getModule('toolbar');
                toolbar.addHandler('image', imageHandler);
            }
        }

        initializeQuill();
    }, []); // Пустой массив зависимостей

    function imageHandler() {
        setIsImageModalOpen(true);
    }

    const selectImage = (url) => {
        try {
            const quill = quillRef.current.getEditor();
            const range = quill.getSelection();
            quill.insertEmbed(range.index, 'image', url);
            setIsImageModalOpen(false);
        } catch (error) {
            console.error('Ошибка при вставке изображения:', error);
        }
    };

    const modules = {
        toolbar: {
            container: "#toolbar",
        }
    };

    return (
        <div className="flex overflow-hidden h-screen bg-[#242424]">
            <Sidebar nav={6} />

            {isImageModalOpen && (
                    <div className='absolute z-[9999] flex justify-center  items-center top-0 left-0 w-full h-full'>
                        <div className="bg-gray p-4 shadow-xl rounded-xl">
                            <div className='flex justify-between items-center'>
                                <h2 className='text-white text-xl font-semibold mb-4'>Select an image</h2>
                                <button className='bg-red rounded-xl text-white px-4 py-2 font-bold' onClick={() => setIsImageModalOpen(false)}>Close</button>
                            </div>

                            <div className="image-list grid grid-cols-4 gap-4 h-[600px] overflow-auto">
                                {imageList.map((url, index) => (
                                    <img
                                        key={index}
                                        src={url}
                                        alt={` ${index + 1}`}
                                        onClick={() => selectImage(url)}
                                        className="w-[150px] h-[150px] object-cover bg-gray"
                                    />
                                ))}
                            </div>

                        </div>
                    </div>

                )}

            <main className="flex flex-col w-full h-[100vh] p-8">
                {isModalOpen && (
                    <div className='fixed  inset-0 flex justify-center items-center bg-black bg-opacity-50 z-20'>
                        <div className='flex h-[70vh] overflow-auto flex-col gap-4 bg-[#1d1d1d] p-8 rounded-xl shadow-2xl relative'>
                            <button
                                onClick={handleCloseModal}
                                className='absolute top-4 right-4 text-white'
                            >
                                <XMarkIcon className='w-6 h-6' />
                            </button>
                            <h4 className='text-white font-semibold'>
                                Редактировать пост
                            </h4>
                            {/* Добавляем поля для редактирования поста */}
                            <div>
                                <label className="block text-white font-semibold mb-2">Title</label>
                                <input
                                    type="text"
                                    name="title"
                                    value={currentPost?.title || ''}
                                    onChange={(e) => setCurrentPost({ ...currentPost, title: e.target.value })}
                                    className="w-full p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div>
                                <label className="block text-white font-semibold mb-2">Link</label>
                                <input
                                    type="text"
                                    name="link"
                                    value={currentPost?.link || ''}
                                    onChange={(e) => setCurrentPost({ ...currentPost, link: e.target.value })}
                                    className="w-full p-2 border border-gray-300 rounded"
                                />
                            </div>
                            <div id="toolbar" className='text-white'>
                                            <button className="ql-bold"></button>
                                            <button className="ql-italic"></button>
                                            <button className="ql-underline"></button>
                                            <button className="ql-list">
                                                <ListBulletIcon />
                                            </button>
                                            <button className="ql-header" value="1">h1</button>
                                            <button className="ql-header" value="2"></button>
                                            <button className="ql-blockquote"></button>
                                            <button className="ql-code-block"></button>
                                            <button className="ql-link"></button>
                                            <button className="ql-align"></button>
                                            <button className="ql-image"></button>
                                        </div>
                            <ReactQuill
                                value={content}
                                onChange={setContent}
                                modules={modules}
                                formats={[
                                    'bold', 'italic', 'underline', 'strike', 'blockquote',
                                    'list', 'bullet', 'indent', 'header',
                                    'link', 'image', 'align'
                                ]}
                                ref={quillRef}
                            />
                            <button onClick={handleEditPost} className='bg-blue-500 text-white p-2 rounded'>Сохранить изменения</button>
                        </div>
                    </div>
                )}

                <div className='mb-8 flex justify-between items-center'>
                    <div className=' sticky top-20'>
                        <h2 className='text-white font-bold text-[32px]'>
                            Posts
                        </h2>
                        <p className='text-white mt-2 opacity-30'>Posts: {allPosts.length}</p>
                    </div>
                </div>

                <div className="mb-6 h-[80vh] w-full overflow-auto">
                    <ul className="space-y-4">
                        {allPosts.map(post => (
                            <li key={post.id} className="flex justify-between items-center bg-gray-800 p-4 rounded-lg">
                                <div className="text-white">{post.title}</div>
                                <div className="space-x-4">
                                    <button onClick={() => handleOpenModal(post)} className="bg-green-500 text-white p-2 rounded">Редактировать</button>
                                    <button onClick={() => handleDeletePost(post._id)} className="bg-red-500 text-white p-2 rounded">Удалить</button>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </main>
        </div>
    );
}

export default AdminProfilePostsList;
