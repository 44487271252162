import { useEffect, useState, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';
import Sidebar from '../sidebar/sidebar';
import { XMarkIcon, ListBulletIcon, ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import ReactQuill from 'react-quill';

function AdminProfilePosts() {
    const [content, setContent] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const quillRef = useRef(null);
    const [allPosts, setAllPosts] = useState([]);

    useEffect(() => {
        async function fetchFiles() {
            try {
                const response = await fetch('https://form.ehoss.com/api/posts');
                if (!response.ok) {
                    throw new Error('Ошибка сети');
                }
                const data = await response.json();
                setAllPosts(data);
            } catch (error) {
                console.error('Ошибка при загрузке файлов:', error);
            }
        }

        fetchFiles();
    }, []);

    const initialFormData = {
        id: 11,
        post_group: {
            id: undefined,
        },
        title: "",
        link: "",
        minidesckr: "",
        seo_deskr: "",
        seo_tite: "",
        seo_keywords: "",
        content: content,
        category: "",
        date: "",
        image: "",
        slider: [],
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSliderChange = (index, e) => {
        const { name, value } = e.target;
        const newSlider = formData.slider.map((item, i) => (i === index ? { ...item, [name]: value } : item));
        setFormData({ ...formData, slider: newSlider });
    };

    const addSliderItem = () => {
        setFormData({
            ...formData,
            slider: [...formData.slider, { path: '', alt: '', title: '' }],
        });
    };

    // Function to handle form submission
    const postFormData = async () => {
        try {
            // Ensure that formData.content has the content from the editor
            const updatedFormData = { ...formData, content };
            const response = await fetch('https://form.ehoss.com/api/posts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedFormData),
            });

            if (!response.ok) {
                throw new Error('Error submitting data');
            }

            const data = await response.json();
            console.log('Successful submission:', data);
            // Additional actions after successful submission
            setFormData(initialFormData);
            setContent(''); // Clear content after submission
        } catch (error) {
            console.error('Error submitting post:', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postFormData(); // Call function to submit data
    };

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
            window.location = '/admin-login';
            console.log('Token is invalid or expired');
        }
    }, []);

    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [imageList, setImageList] = useState([]);

    useEffect(() => {
        async function fetchImages() {
            try {
                const response = await fetch('https://form.ehoss.com/api/files');
                const data = await response.json();
                const imageUrls = data.map(file => `https://form.ehoss.com/uploads/${file.file_name}`);
                setImageList(imageUrls);
            } catch (error) {
                console.error('Error fetching image list:', error);
            }
        }

        fetchImages();
    }, []);

    useEffect(() => {
        const quill = quillRef.current.getEditor();
        const toolbar = quill.getModule('toolbar');
        toolbar.addHandler('image', imageHandler);
    }, []);

    function imageHandler() {
        setIsImageModalOpen(true);
    }

    const selectImage = (url) => {
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection();
        quill.insertEmbed(range.index, 'image', url);
        setIsImageModalOpen(false);
    };

    const modules = {
        toolbar: {
            container: "#toolbar",
        }
    };

    return (
        <div className="flex overflow-hidden h-screen bg-[#242424]">
            <Sidebar nav={3} />
            <main className="flex flex-col w-full h-[100vh] p-8">
                {isModalOpen && (
                    <div className='fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-20'>
                        <div className='flex flex-col gap-4 bg-[#1d1d1d] p-8 rounded-xl shadow-2xl relative'>
                            <button
                                onClick={handleCloseModal}
                                className='absolute top-4 right-4 text-white'
                            >
                                <XMarkIcon className='w-6 h-6' />
                            </button>
                            <h4 className='text-white font-semibold'>
                                Add new post
                            </h4>
                        </div>


                    </div>
                )}

                {isImageModalOpen && (
                    <div className='absolute flex justify-center  items-center top-0 left-0 w-full h-full'>
                        <div className="bg-gray p-4 shadow-xl rounded-xl">
                            <div className='flex justify-between items-center'>
                                <h2 className='text-white text-xl font-semibold mb-4'>Select an image</h2>
                                <button className='bg-red rounded-xl text-white px-4 py-2 font-bold' onClick={() => setIsImageModalOpen(false)}>Close</button>
                            </div>

                            <div className="image-list grid grid-cols-4 gap-4 h-[600px] overflow-auto">
                                {imageList.map((url, index) => (
                                    <img
                                        key={index}
                                        src={url}
                                        alt={` ${index + 1}`}
                                        onClick={() => selectImage(url)}
                                        className="w-[150px] h-[150px] object-cover bg-gray"
                                    />
                                ))}
                            </div>

                        </div>
                    </div>

                )}

                <div className='mb-8 flex justify-between items-center'>
                    <div className=' sticky top-20'>
                        <h2 className='text-white font-bold text-[32px]'>
                            Posts
                        </h2>
                        <p className='text-white mt-2 opacity-30'>Posts: {allPosts.length}</p>
                    </div>
                </div>
                <main className="flex">
                    <div className="mb-6 h-[80vh] w-full overflow-auto">
                        <form onSubmit={handleSubmit} className=" flex justify-between space-y-4">
                            <div className='w-full'>
                                <div className='flex flex-col gap-4'>
                                    <div>
                                        <label className="block text-white font-semibold mb-2">Title</label>
                                        <input
                                            type="text"
                                            name="title"
                                            value={formData.title}
                                            onChange={handleChange}
                                            className="w-full p-2 border border-gray-300 rounded"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-white font-semibold mb-2">Post custom link</label>
                                        <input
                                            type="text"
                                            name="link"
                                            value={formData.link}
                                            onChange={handleChange}
                                            className="w-full p-2 border border-gray-300 rounded"
                                        />
                                    </div>


                                    <div>
                                        <label className="block text-white font-semibold mb-2">Mini Description</label>
                                        <textarea
                                            name="minidesckr"
                                            value={formData.minidesckr}
                                            onChange={handleChange}
                                            className="w-full p-2 border border-gray-300 rounded"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-white font-semibold mb-2">SEO Description</label>
                                        <textarea
                                            name="seo_deskr"
                                            value={formData.seo_deskr}
                                            onChange={handleChange}
                                            className="w-full p-2 border border-gray-300 rounded"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-white font-semibold mb-2">SEO Title</label>
                                        <input
                                            type="text"
                                            name="seo_tite"
                                            value={formData.seo_tite}
                                            onChange={handleChange}
                                            className="w-full p-2 border border-gray-300 rounded"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-white font-semibold mb-2">SEO Keywords</label>
                                        <input
                                            type="text"
                                            name="seo_keywords"
                                            value={formData.seo_keywords}
                                            onChange={handleChange}
                                            className="w-full p-2 border border-gray-300 rounded"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-white font-semibold mb-2">Category</label>
                                        <input
                                            type="text"
                                            name="category"
                                            value={formData.category}
                                            onChange={handleChange}
                                            className="w-full p-2 border border-gray-300 rounded"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-white font-semibold mb-2">Date</label>
                                        <input
                                            type="date"
                                            name="date"
                                            value={formData.date}
                                            onChange={handleChange}
                                            className="w-full p-2 border border-gray-300 rounded"
                                        />
                                    </div>

                                    <div>
                                        <label className="block text-white font-semibold mb-2">Image</label>
                                        <input
                                            type="text"
                                            name="image"
                                            value={formData.image}
                                            onChange={handleChange}
                                            className="w-full p-2 border border-gray-300 rounded"
                                        />
                                    </div>

                                    {/* <div className="mb-4">
                                        <label className="block text-white font-semibold mb-2">Slider</label>
                                        {formData.slider.map((item, index) => (
                                            <div key={index} className="mb-2">
                                                <input
                                                    type="text"
                                                    name="path"
                                                    value={item.path}
                                                    onChange={(e) => handleSliderChange(index, e)}
                                                    placeholder="Path"
                                                    className="w-full p-2 border border-gray-300 rounded mb-1"
                                                />
                                                <input
                                                    type="text"
                                                    name="alt"
                                                    value={item.alt}
                                                    onChange={(e) => handleSliderChange(index, e)}
                                                    placeholder="Alt Text"
                                                    className="w-full p-2 border border-gray-300 rounded mb-1"
                                                />
                                                <input
                                                    type="text"
                                                    name="title"
                                                    value={item.title}
                                                    onChange={(e) => handleSliderChange(index, e)}
                                                    placeholder="Title"
                                                    className="w-full p-2 border border-gray-300 rounded mb-1"
                                                />
                                            </div>
                                        ))}
                                    </div> */}


                                </div>


                                <div>
                                    <label className="block text-white text-gray-700">Content</label>
                                    <div>
                                        <div id="toolbar" className='text-white'>
                                            <button className="ql-bold"></button>
                                            <button className="ql-italic"></button>
                                            <button className="ql-underline"></button>
                                            <button className="ql-list">
                                                <ListBulletIcon />
                                            </button>
                                            <button className="ql-header" value="1">h1</button>
                                            <button className="ql-header" value="2"></button>
                                            <button className="ql-blockquote"></button>
                                            <button className="ql-code-block"></button>
                                            <button className="ql-link"></button>
                                            <button className="ql-align"></button>
                                            <button className="ql-image"></button>
                                        </div>
                                        <ReactQuill
                                            ref={quillRef}
                                            value={content}
                                            onChange={setContent}
                                            modules={modules}
                                            formats={[
                                                'bold', 'italic', 'underline', 'strike', 'blockquote',
                                                'list', 'bullet', 'indent', 'header',
                                                'link', 'image', 'align'
                                            ]}
                                            className="mb-2 bg-white rounded-xl border-none"
                                        />
                                    </div>



{/* Button to Add Slider Item */}
                                    <div>
                                        <button
                                            type="button"
                                            onClick={addSliderItem}
                                            className="px-4 py-2 bg-green-500 text-white rounded mt-4"
                                        >
                                            Add Slider Item
                                        </button>
                                    </div>

                                    {/* Display slider items and counter */}
                                    {formData.slider.length > 0 && (
                                        <div>
                                            <h3 className="text-white font-semibold mt-4">Slider Items: {formData.slider.length}</h3>
                                            {formData.slider.map((item, index) => (
                                                <div key={index} className="mt-2 p-2 border border-gray-300 rounded">
                                                    <label className="block text-white font-semibold mb-2">Path</label>
                                                    <input
                                                        type="text"
                                                        name="path"
                                                        value={item.path}
                                                        onChange={(e) => handleSliderChange(index, e)}
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                    />

                                                    <label className="block text-white font-semibold mb-2">Alt</label>
                                                    <input
                                                        type="text"
                                                        name="alt"
                                                        value={item.alt}
                                                        onChange={(e) => handleSliderChange(index, e)}
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                    />

                                                    <label className="block text-white font-semibold mb-2">Title</label>
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        value={item.title}
                                                        onChange={(e) => handleSliderChange(index, e)}
                                                        className="w-full p-2 border border-gray-300 rounded"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}




                                    <button
                                        type="submit"
                                        className="bg-red w-full text-white py-2 px-4 rounded"
                                    >
                                        Опубликовать пост
                                    </button>

                                </div>
                            </div>


                        </form>
                    </div>
                </main>
            </main>
        </div>
    );
}

export default AdminProfilePosts;
