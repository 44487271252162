import React, { useEffect, useState } from 'react';

const HeadScripts = () => {
    const [scripts, setScripts] = useState([]);

    useEffect(() => {
        const fetchScripts = async () => {
            try {
                const response = await fetch('https://form.ehoss.com/api/scripts');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setScripts(data);
            } catch (error) {
                console.error('Failed to fetch scripts:', error);
            }
        };

        fetchScripts();
    }, []);

    useEffect(() => {
        const head = document.head;

        // Clear existing script content to avoid duplication
        const existingElements = head.querySelectorAll('script[data-from-db]');
        existingElements.forEach(el => head.removeChild(el));

        // Add new script content directly to <head>
        scripts.forEach(script => {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = script.scriptContent;

            // Extract and append all script tags directly to <head>
            Array.from(tempDiv.childNodes).forEach(node => {
                if (node.tagName === 'SCRIPT') {
                    head.appendChild(node);
                } else {
                    console.warn('Skipping non-script tag:', node);
                }
            });
        });
    }, [scripts]);

    return null; // Этот компонент не рендерит ничего в разметку, только управляет <head>
};

export default HeadScripts;