import React, { useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import Footer from '../../container/footer/Footer';
import './postpage.css';
import Line from '../../components/line/Line';
import PostsWidget from '../../components/PostsWidget/PostsWidget';
import postsData from '../../data/postsData';
import ShareButtons from '../../components/share-buttons/ShareButtons';
import SliderComponent from '../../components/slider/slider';
import { CalendarDaysIcon, ClockIcon, HashtagIcon } from "@heroicons/react/24/outline";
import img from './mail-img.svg'
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const PostPage = (activeCategory, onSelectCategory) => {
  const location = useLocation();
  const { postLink } = useParams();
  const post = postsData.find((post) =>
    post.title.replace(/ /g, "-")
      .replace(/:/g, "") // Удаляем двоеточия
      .replace(/[-–—]+/g, "-") // Заменяем последовательности тире на одно тире
      .replace(/[^a-zA-Z0-9- ]/g, "") // Удаляем специальные символы, оставляя только буквы, цифры, тире и пробелы
      .replace(/ /g, "-")
      .toLowerCase() === postLink);
  const postsRef = useRef(null);

  if (!post) {
    return <p>Пост не найден</p>;
  }

  function openRequestModal() {
    const modal = document.getElementById('contact-modal');
    modal.classList.remove('hidden');
    modal.classList.add('flex');
  }

  console.log(`https://ehoss.com/${post.image}`);


  return (
    <section>
      <Helmet>
        <title>EHOSS | {post.title}</title>
        <meta property="og:type" content="article" />
        <meta property="og:title" content={post.title} />
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content={post.seo_keywords} />
        <meta name="description" content={post.seo_deskr} />
        <meta property="og:image" content={`https://ehoss.com${post.image}`} />
        <link rel="canonical" href={`https://ehoss.com${location.pathname}`} />
      </Helmet>
      <div className='fixed h-[100vh] -z-10'>
        <Line />
      </div>
      <div className='container'>
        <div className='pt-24'>
          <Breadcrumbs
            items={[
              { label: 'Company', url: '#' },
              { label: 'Blog', url: '/blog' },
              { label: post.category, url: `/${post.category.replace(/\s+/g, '-').toLowerCase()}` },
              { label: post.title.length > 14 ? post.title.substring(0, 14) + '...' : post.title, url: '#' }
            ]}
          />
        </div>
      </div>
      <div className='container flex md:flex-row flex-col gap-6'>
        <div id='post' ref={postsRef} className="md:w-2/3 order-2 md:order-1">
          <h1 className="md:text-[48px] md:mt-12 text-[24px] font-semibold text-white mb-6">{post.title}</h1>
          <div className='flex flex-wrap justify-between items-center mb-6'>
            <div className="flex text-white items-center gap-3">
              <p className="bg-white flex items-center text-[12px] md:text-[14px] text-black p-[4px] rounded-lg">
                <HashtagIcon className="h-4 w-4 text-red" />
                {post.category.replace(/ /g, "").toLowerCase()}
              </p>
              <p className="flex items-center gap-1 text-white text-[14px]">
                <CalendarDaysIcon className="h-4 w-4" />
                {post.date}
              </p>
              <p title='Reading time' className="hidden items-center gap-1 text-[14px]">
                <ClockIcon className="h-4 w-4" />
                6 min
              </p>
            </div>
            <ShareButtons />
          </div>
          {/* <img src={post.image} alt="" /> */}
          <p dangerouslySetInnerHTML={{ __html: post.content }}></p>
          {post.slider && post.slider.length > 0 && (
            <SliderComponent imagePaths={post.slider} />
          )}
        </div>
        <div className="md:w-1/3 pt-12 md:pt-0 md:sticky h-auto md:h-[480px] md:top-[120px] order-1 md:order-2">
          <ul className="flex-wrap flex gap-4 *:pl-3 *:pb-2 *:pt-2 *:pr-3 *:rounded-xl *:border-[1px] *:border-white">
            <li>
              <a className="flex items-center gap-2 text-[16px] font-medium text-white" href="/blog">
                <HashtagIcon className="h-4 w-4 text-white" />
                All categories
              </a>
            </li>
            <li>
              <a className="flex items-center gap-2 text-[16px] font-medium text-white" href="/articles"><HashtagIcon className="h-4 w-4 text-white" />Articles</a>
            </li>
            <li>
              <a className="flex items-center gap-2 text-[16px] font-medium text-white" href="/press-about-us"><HashtagIcon className="h-4 w-4 text-white" />Press about us</a>
            </li>
            <li>
              <a className="flex items-center gap-2 text-[16px] font-medium text-white" href="/events"><HashtagIcon className="h-4 w-4 text-white" />Events</a>
            </li>
          </ul>
          <div className='bg-gray mt-8 rounded-lg hidden md:block'>
            <div className='p-4'>
              <h3 className='font-semibold mb-10 leading-[120%] text-[32px] text-white'>
                Have an idea? <br />
                Lets get in touch
              </h3>
              <div className='flex gap-4 items-center '>
                <a href='/contacts' className='main-btn'>Contacts</a>
              </div>
            </div>
            <div className='flex w-full justify-end'>
              <img alt='' src={img} />
            </div>
          </div>
        </div>
      </div>
      <PostsWidget
        filter={'1'}
        currentPost={post.id}
        firstPosts={post.post_group.id || undefined}
      />      
      <Footer />
    </section>
  );
};

export default PostPage;